import React, { useState, useEffect, useCallback } from 'react'
import QuestionsControler from '../../appComponents/questions/questionsControler'
import { useCreateResponseMutation } from '../../features/response/responsesSlice'
import { useGetCommentsQuery } from '../../features/SurveyComments/surveyCommentsSlice'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

function Questions({ displayComments, surveyQuestions, disabled, responses, commentUser, showComments }) {
  const [createResponse, { isLoading, isSuccess, isError }] = useCreateResponseMutation()
  const { idCampaign, idUser } = useParams()

  const data = { campaign: idCampaign, user: commentUser }
  const { data: comments } = useGetCommentsQuery(data)

  const [responseState, setResponseState] = useState({})

  const debouncedCreateResponse = useCallback(
    debounce((data) => {
      createResponse(data)
    }, 1000), // Adjust debounce delay as needed
    [createResponse]
  )

  const handleSaveQuestion = (data) => {
    const updatedResponseState = { ...responseState, ...data, campaign: idCampaign, user: idUser }
    setResponseState(updatedResponseState)
    debouncedCreateResponse(updatedResponseState)
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      debouncedCreateResponse.flush() // Ensure pending requests are sent
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      debouncedCreateResponse.cancel() // Clean up debounced function
    }
  }, [debouncedCreateResponse])

  return (
    <div className="bg-[#f5f4f0] flex flex-col gap-1 w-full rounded-md p-1">
      {surveyQuestions
        ? surveyQuestions.map((question, index) => (
            <QuestionsControler
              displayComments={displayComments}
              showComments={showComments}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              disabled={disabled}
              response={responses ? responses.find((resp) => resp.question === question.uuid) : undefined}
              questionHelpText={question?.question_help_text}
              comment={comments ? comments.find((comment) => comment.question === question.uuid) : {}}
              saveResponse={handleSaveQuestion}
              commentUser={commentUser}
              key={question?.uuid ? question.uuid : index}
              question={question}
            />))
        : undefined}
    </div>
  )
}

Questions.propTypes = {
  surveyQuestions: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  displayComments: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  showComments: PropTypes.string
}

export default Questions
