import { useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";

const MARGIN = { top: 30, right: 30, bottom: 80, left: 100 };
const BUBBLE_MIN_SIZE = 5;
const BUBBLE_MAX_SIZE = 25;

type BubblePlotProps = {
  width: number;
  height: number;
  data: {
    y_risk: number;
    x_risk: number;
    parent_text: string;
    impact: number;
  }[];
};

export const BubblePlot = ({ width, height, data }: BubblePlotProps) => {
  const [displayedLabel, setDisplayedLabel] = useState(""); // State to keep track of the label to display
  let onMouseOver = (event: any, d: any) => {
    setDisplayedLabel(d.label);
    
  }
  // Layout. The div size is set by the given props.
  // The bounds (=area inside the axis) is calculated by substracting the margins
  const axesRef = useRef(null);
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  let axisOriginOffset = 20

  // Scales
  const yScale = useMemo(() => {
    const [min, max] = [0,20]
    return d3.scaleLinear().domain([min, max]).range([boundsHeight, 0]).nice();
  }, [data, height]);

  const xScale = useMemo(() => {
    const [min, max] = [0, 20]/*d3.extent(data.map((d) => d.x_risk)) as [
      number,
      number
    ];*/
    return d3.scaleLinear().domain([0, max]).range([0, boundsWidth]).nice();
  }, [data, width]);

  const groups = data
    .map((d) => d.parent_text)
    .filter((x, i, a) => a.indexOf(x) == i);

  const colorScale = d3
    .scaleOrdinal<string>()
    .domain(groups)
    .range(["#e0ac2b", "#e85252", "#6689c6", "#9a6fb0", "#a53253"]);

  const sizeScale = useMemo(() => {
    const [min, max] = d3.extent(data.map((d) => d.impact)) as [number, number];
    return d3
      .scaleSqrt()
      .domain([min, max])
      .range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE]);
  }, [data, width]);

  // Render the X and Y axis using d3.js, not react
  useEffect(() => {
    const svgElement = d3.select(axesRef.current);
    svgElement.selectAll("*").remove();

    const xAxisGenerator = d3.axisBottom(xScale);
    svgElement
      .append("g")
      .attr("transform", "translate(0," + (boundsHeight + axisOriginOffset) + ")")
      .call(xAxisGenerator);
    svgElement
      .append("text")
      .attr("x", boundsWidth / 2)
      .attr("y", boundsHeight + MARGIN.top + 33) // Adjust the y position
      .attr("dx", "10px")
      .attr("text-anchor", "middle")
      .attr("font-size", 20)
      .text("Enjeux internes");

    const yAxisGenerator = d3.axisLeft(yScale);
    svgElement
      .append("g")
      .attr("transform", "translate(" + -axisOriginOffset + ",0)")
      .call(yAxisGenerator);
    svgElement
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", 0 - boundsHeight / 2)
      .attr("y", -MARGIN.left+ 10)
      .attr("dy", "10px")
      .attr("text-anchor", "middle")
      .attr("font-size", 20)
      .text("Enjeux externes pour vos parties prenantes ")
      .attr("transform", "rotate(-90)");
  }, [xScale, yScale, boundsHeight, boundsWidth]);

  // Build the shapes
  const allShapes = data
    .sort((a, b) => b.impact - a.impact)
    .map((d, i) => {
      return (
        <circle
          key={i}
          r={sizeScale(d.impact)}
          cx={xScale(d.x_risk)}
          cy={yScale(d.y_risk)}
          opacity={1}
          stroke={colorScale(d.parent_text)}
          fill={colorScale(d.parent_text)}
          fillOpacity={0.4}
          strokeWidth={1}
          onMouseOver={(event) => onMouseOver(event, d)}
        />
      );
    });

  return (
    <div>
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          {allShapes}
        </g>
        <g
          width={boundsWidth}
          height={boundsHeight}
          ref={axesRef}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
      </svg>
      <div>{displayedLabel}</div>
    </div>
  );
};
